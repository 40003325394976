const styles = {
  padding: "1rem",

  titleSize:
    "xl:text-[2.3rem] lg:text-[2rem] md:text-[1.8rem] sm:text-[1.5rem] xs:text-[1.5rem] text-[1.5rem] text-[#FFFFFF] font-medium",

  subtitleSize:
    "xl:text-[2.5rem] lg:text-[2rem] md:text-[1.5rem] sm:text-[1.5rem] xs:text-[1rem] text-[1rem] text-[#FFFFFF]",

  cardsTitleSize:
    "xl:text-[1.5rem] lg:text-[1.3rem] md:text-[1.3rem] sm:text-[1.2rem] xs:text-[1rem] text-[1rem] text-[#FFFFFF]",

  cardsNumberSize:
    "xl:text-[2rem] lg:text-[1.8rem] md:text-[1.5rem] sm:text-[1.5rem] xs:text-[1rem] text-[1rem] text-[#FFFFFF]",

  regularSize:
    "xl:text-[1.5rem] lg:text-[1rem] md:text-[0.8rem] sm:text-[o.8rem] xs:text-[0.5rem] text-[0.5rem] text-[#FFFFFF]",

  sideNavbarSize:
    "xl:text-[1.5rem] lg:text-[1.5rem] md:text-[1.2rem] sm:text-[1.2rem] xs:text-[1rem] text-[1rem]",

  // Holiday Section
  holidayNameSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#FFFFFF]",

  holidayDateSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#a6a6a6] font-light",

  holidayMonthSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#D7AE83] font-light",

  // Event Section
  eventNameSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#FFFFFF]",

  eventDateSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#a6a6a6] font-light",

  eventTimeSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#a6a6a6] font-light",

  eventLocationSize:
    "xl:text-[1rem] lg:text-[1rem] md:text-[1rem] sm:text-[1rem] xs:text-[1rem] text-[1rem] text-[#D7AE83] font-light",
};

const punchButtonSxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  background: "linear-gradient(90deg, #E0B68A 0%, #9B7655 100%)",
  width: "158px",
  height: "52px",
  borderRadius: "39px",
  color: "#000000",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "23.44px",
  textTransform: "none",
  transition: "all 0.3s ease",
  boxShadow:
    "5px 5px 15px rgba(0, 0, 0, 1), -2px -2px 15px rgba(164, 164, 164, 0.9)",
  "&:hover": {
    boxShadow: "5px 5px 20px rgba(0, 0, 0, 1)",
    background: "linear-gradient(90deg, #E0B68A 10%, #9B7655 90%)",
  },
  padding: "10px",

  // Responsive size adjustments for smaller screens
  "@media (max-width:500px)": {
    width: "120px",
    height: "40px",
    fontSize: "14px",
  },
};

const resetButtonSxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  background: "linear-gradient(90deg, #FFFFFF 0%, #535353 100%)",
  width: "158px",
  height: "52px",
  borderRadius: "39px",
  color: "#000000",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "23.44px",
  textTransform: "none",
  transition: "all 0.3s ease",
  boxShadow:
    "5px 5px 15px rgba(0, 0, 0, 1), -2px -2px 15px rgba(164, 164, 164, 0.9)",
  "&:hover": {
    boxShadow: "5px 5px 20px rgba(0, 0, 0, 1)",
    background: "linear-gradient(90deg, #FFFFFF 10%, #535353 90%)",
  },
  padding: "10px",

  // Responsive size adjustments for smaller screens
  "@media (max-width:500px)": {
    width: "120px",
    height: "40px",
    fontSize: "14px",
  },
};

// Registration Page Text Fields Style
// Text Field Style
const textFieldInputStyle = {
  color: "#EDF6FF",
  backgroundColor: "#282828",
  borderRadius: "0.5rem",
  borderWidth: "0.1rem",
  borderColor: "#D7AE83",
  outline: "none",
};

const textFieldInputLabelStyle = {
  color: "#999999",
};

const textFieldSxStyle = {
  // Remove the underline
  "& .MuiFilledInput-root": {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:hover:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
  },
};

// Dropdown Text Field Style
const textFieldDropdownSxStyle = {
  ".MuiSelect-icon": {
    color: "#999999",
  },
  "&::before": {
    borderBottom: "none",
  },
  "&::after": {
    borderBottom: "none",
  },
  "&:hover:not(.Mui-disabled)::before": {
    borderBottom: "none",
  },
};

// Profile Details Page - Reporting Dropdown Button
const textFieldReportingDropdownSxStyle = {
  // Custom styles
  "& .MuiSelect-icon": {
    color: "#999999",
  },
  "& .MuiSelect-select": {
    color: "#FFFFFF !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D7AE83",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D7AE83",
  },
  "&.Mui-disabled": {
    color: "#FFFFFF !important",
    backgroundColor: "#282828",
    borderColor: "#D7AE83",
    "& .MuiSelect-icon": {
      color: "#999999",
    },
    "& .MuiSelect-select": {
      color: "#FFFFFF !important",
    },
    "& .MuiSelect-select.Mui-disabled": {
      color: "#FFFFFF !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D7AE83 !important",
    },
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
  },
  "&::before": {
    borderBottom: "none",
  },
  "&::after": {
    borderBottom: "none",
  },
  "&:hover:not(.Mui-disabled)::before": {
    borderBottom: "none",
  },
};

// Dropdown Panel Style
const textFieldDropdownMenuPropsStyle = {
  bgcolor: "#282828",
  "& .MuiMenuItem-root": {
    color: "#FFFFFF",
    "&:hover": {
      bgcolor: "#D7AE83",
    },
  },
};

// Upload Image and Pdf Text Field Style
const textFieldUploadLabelStyle = {
  display: "block",
  backgroundColor: "#282828",
  borderRadius: "0.5rem",
  borderWidth: "0.1rem",
  borderColor: "#D7AE83",
  outline: "none",
  padding: "13px",
  cursor: "pointer",
  textAlign: "left",
};

// Image and Pdf Preview Section Style
const textFieldUploadImageStyle = {
  maxWidth: "100%",
  maxHeight: "200px",
  justifyContent: "center",
  alignItems: "center",
};

// Searchable Text Field Style
const textFieldSearchableStyle = {
  backgroundColor: "#282828",
  borderRadius: "0.5rem",
};

const textFieldSearchableSxStyle = {
  // Text field input styles
  "& .MuiFilledInput-root": {
    color: "#EDF6FF",
    backgroundColor: "#282828",
    borderRadius: "0.5rem",
    border: "0.1rem solid #D7AE83",

    // Remove the underline
    "&:before": {
      borderBottom: "none !important",
    },
    "&:hover:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
  },
  // Label styles
  "& .MuiInputLabel-root": {
    color: "#999999 !important",
  },
  // Dropdown and Cross Icon styles
  "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
    color: "#999999",
  },
  "& .MuiAutocomplete-clearIndicator": {
    color: "#999999",
  },
};

const textFieldSearchableChipStyle = {
  // Background color for selected chips
  backgroundColor: "#D7AE83",
  color: "#EDF6FF",
  margin: "2px",
  padding: "0px",
  fontSize: "0.8rem",
  borderRadius: "0.3rem",
};

const textFieldSearchableSpanStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "12px",
  height: "12px",
  // backgroundColor: "#777777",
  borderRadius: "50%",
  padding: "5px",
  marginLeft: "0px",
  boxSizing: "content-box",
};

const textFieldSearchablePaperSxStyle = {
  // Dropdown panel style
  bgcolor: "#282828",
  "& .MuiAutocomplete-option": {
    color: "#EDF6FF",
    "&:hover": {
      bgcolor: "#D7AE83 !important",
    },
  },
  "& .MuiAutocomplete-noOptions": {
    color: "#EDF6FF !important",
  },
};

const textFieldSearchablePopperSxStyle = {
  "& .MuiAutocomplete-popupIndicator": {
    color: "#D7AE83",
  },
};

// Radio Button Style
const textFieldRadioButtonSxStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  color: "#999999",
  backgroundColor: "#282828",
  borderRadius: "0.5rem",
  border: "0.1rem solid #D7AE83",
  padding: "0.5rem",
};

// Register Button Style
const registerButtonSxStyle = {
  color: "#111111",
  backgroundColor: "#D7AE83",
  borderRadius: "0.5rem",
  padding: "0.5rem 2rem",
  letterSpacing: "0.2rem",
  fontSize: "1rem",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "#363636",
    color: "#D7AE83",
  },
};

// Delete Button Style
const deleteButtonSxStyle = {
  backgroundColor: "#d7ae83",
  color: "#111111",
  fontSize: "1rem",
  fontWeight: 600,
  padding: "5px 15px",
  border: "none",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#111111",
    color: "#EDF6FF",
  },
};

// Edit and Update Button
const editUpdateButtonSxStyle = {
  color: "#111111",
  fontSize: "1rem",
  fontWeight: 600,
  padding: "5px 15px",
  border: "none",
  borderRadius: "5px",
};

// Dialog Box Style
const dialogBoxSxStyle = {
  borderRadius: "1rem",
  backgroundColor: "#333333",
  padding: "2rem",
  display: "flex",
};

const dialogBoxTitleSxStyle = {
  color: "#EDF6FF",
  padding: "0px",
  marginBottom: "1.5rem",
};

const dialogBoxCancelSxStyle = {
  color: "#EDF6FF",
  backgroundColor: "#212121",
  borderRadius: "5px",
  padding: "5px 10px",
  "&:hover": {
    color: "#f70202",
    backgroundColor: "#111111",
    borderRadius: "5px",
  },
};

const dialogBoxConfirmSxStyle = {
  color: "#EDF6FF",
  backgroundColor: "#212121",
  borderRadius: "5px",
  padding: "5px 10px",
  "&:hover": {
    color: "#d7ae83",
    backgroundColor: "#111111",
    borderRadius: "5px",
  },
};

export {
  styles,
  punchButtonSxStyle,
  resetButtonSxStyle,

  // Registration Page
  textFieldInputStyle,
  textFieldInputLabelStyle,
  textFieldSxStyle,
  textFieldDropdownSxStyle,
  textFieldReportingDropdownSxStyle,
  textFieldDropdownMenuPropsStyle,
  textFieldUploadLabelStyle,
  textFieldUploadImageStyle,
  textFieldSearchableStyle,
  textFieldSearchableSxStyle,
  textFieldSearchableChipStyle,
  textFieldSearchableSpanStyle,
  textFieldSearchablePaperSxStyle,
  textFieldSearchablePopperSxStyle,
  textFieldRadioButtonSxStyle,
  registerButtonSxStyle,
  deleteButtonSxStyle,
  editUpdateButtonSxStyle,
  dialogBoxSxStyle,
  dialogBoxTitleSxStyle,
  dialogBoxCancelSxStyle,
  dialogBoxConfirmSxStyle,
};
