import React, { useEffect, useState } from "react";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { useUser } from "../../context/userContext";
import { Button, Divider, FormControl, MenuItem, Select } from "@mui/material";
import {
  showErrorToast,
  showSuccessToast,
} from "../toastNotification/toastNotification";
import { supportPanelIssueData } from "../../constants/supportPanelData";
import { textFieldDropdownMenuPropsStyle } from "../../styles";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
import html2canvas from "html2canvas";

const SupportPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [viewScreenshot, setViewScreenshot] = useState(null);
  const [emailError, setEmailError] = useState("");

  const { user } = useUser();

  const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Set email, if user is signed in
  useEffect(() => {
    if (user?.empWorkEmail) {
      setEmail(user.empWorkEmail);
    }
  }, [user]);

  const togglePanel = () => setIsOpen(!isOpen);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    // Validate email and set error message if invalid
    if (e.target.value && !isValidEmail.test(e.target.value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (file && file.size > maxFileSize) {
      showErrorToast("File size exceeds 5MB.");
      return;
    }

    if (
      file &&
      (file.type === "application/pdf" || file.type.startsWith("image/"))
    ) {
      setAttachment(file);
    } else {
      showErrorToast("Please select a PDF, JPEG, or PNG file.");
    }
  };

  // Function to capture screenshot
  const captureScreenshot = async () => {
    try {
      // Temporarily hide the support panel
      const supportPanel = document.getElementById("support-panel");
      if (supportPanel) {
        supportPanel.style.display = "none";
      }

      const canvas = await html2canvas(document.body);
      const dataUrl = canvas.toDataURL("image/png");

      // console.log("Captured Screenshot Data URL:", dataUrl);

      const screenshotFile = dataURLtoFile(dataUrl, "screenshot.png");

      if (screenshotFile) {
        setScreenshot(screenshotFile);
        // console.log("Screenshot file URL:", screenshotFile);
        showSuccessToast("Screenshot captured successfully!");
      } else {
        showErrorToast("Failed to capture screenshot.");
      }

      // View screenshot
      if (screenshotFile) {
        const screenshotViewUrl = URL.createObjectURL(screenshotFile);
        setViewScreenshot(screenshotViewUrl);
      } else {
        showErrorToast("Failed to capture screenshot.");
      }

      // Restore the support panel visibility
      if (supportPanel) {
        supportPanel.style.display = "block";
      }
    } catch (error) {
      showErrorToast("Failed to capture screenshot.");
      console.error("Screenshot Error:", error);
    }
  };

  // Helper function to convert base64 to a file
  const dataURLtoFile = (dataUrl, filename) => {
    if (!dataUrl || !dataUrl.startsWith("data:")) {
      console.error("Invalid data URL:", dataUrl);
      return null;
    }

    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async () => {
    if (
      !email ||
      emailError ||
      !description ||
      !selectedIssue ||
      (selectedIssue === "other-issue" && !subject)
    ) {
      return showErrorToast("Please fill out all fields.");
    }

    const formData = new FormData();
    formData.append("empId", user?.empId || "");
    formData.append("empCode", user?.empCode || "");
    formData.append("empFirstName", user?.empFirstName || "");
    formData.append("empLastName", user?.empLastName || "");

    formData.append("empWorkEmail", email);
    formData.append(
      "ticketTitle",
      selectedIssue === "other-issue" ? subject : selectedIssue
    );
    formData.append("description", description);

    if (attachment) {
      formData.append("attachment", attachment);
    }

    if (screenshot) {
      formData.append("screenshot", screenshot);
    }

    console.log({
      empId: user?.empId || "",
      empFirstName: user?.empFirstName || "",
      empLastName: user?.empLastName || "",

      email: email,
      ticketTitle: selectedIssue === "other-issue" ? subject : selectedIssue,
      description: description,
      attachment: attachment ? attachment.name : "No file attached",
      screenshot: screenshot ? screenshot.name : "No screenshot taken",
    });

    showSuccessToast("Support request sent!");
    setIsOpen(false);
  };

  return (
    <div>
      {!isOpen && (
        <button
          onClick={togglePanel}
          className="fixed bottom-5 right-5 xs:right-7 bg-color_gold hover:bg-color_black text-[#111111] rounded-full p-4 group"
        >
          <ContactSupportOutlinedIcon
            style={{ fontSize: "30px" }}
            className="text-[#111111] group-hover:text-[#d7ae83]"
          />
        </button>
      )}

      {isOpen && (
        <>
          <div
            id="support-panel"
            className="fixed bottom-5 right-5 xs:right-7 w-[70%] sm:w-[450px] max-h-[80vh] xs:max-full shadow-2xl text-color_white backdrop-blur-md bg-[#1f1f1f] bg-opacity-80 overflow-auto"
            style={{
              borderRadius: "0.5rem",
              padding: "1.5rem",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(#1f1f1f, #1f1f1f), linear-gradient(180deg, #9B7655 0%, #FFFFFF 48.4%, #9B7655 100%)`,
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
            }}
          >
            <h2 className="font-medium text-xl text-[#E0B68A]">
              Raise a Ticket
            </h2>
            <p className="font-light text-sm text-color_white mb-2">
              Our support team is ready to assist you
            </p>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#FFFFFF",
                height: "1px",
                width: "80%",
                alignSelf: "center",
                marginBottom: "1.5rem",
                transform: "scaleY(0.39)",
                transformOrigin: "center",
              }}
            />

            <div className="mb-4">
              <label className="block text-xs text-[#FFFFFF] mb-2">
                Your official email id <span className="text-[#FF0000]">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                readOnly={!!user?.empWorkEmail}
                className={`w-full text-xxs pl-[10px] pr-[10px] leading-[2.2rem] rounded bg-[#000000] border-[0.79px] border-[#5f5f5f] placeholder-[#7c7c7c] focus:outline-none`}
                placeholder="Enter your email"
              />
              {emailError && (
                <p
                  style={{
                    color: "#f70202",
                    fontSize: "12px",
                    padding: "0px 5px 0px 8px",
                  }}
                >
                  {emailError}
                </p>
              )}
            </div>

            <div
              className={`flex flex-col  ${
                selectedIssue === "other-issue" ? "xs:flex-row" : ""
              } mb-4 gap-2`}
            >
              <div className="flex flex-col w-full">
                <label className="block text-xs text-[#FFFFFF] mb-2">
                  Facing any issue? <span className="text-[#FF0000]">*</span>
                </label>

                <FormControl fullWidth>
                  <Select
                    value={selectedIssue}
                    onChange={(e) => setSelectedIssue(e.target.value)}
                    displayEmpty
                    variant="standard"
                    disableUnderline
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <span style={{ color: "#7c7c7c" }}>
                            Select an issue
                          </span>
                        );
                      }
                      return supportPanelIssueData.find(
                        (item) => item.value === selected
                      )?.label;
                    }}
                    sx={{
                      color: "#ffffff",
                      border: "0.79px solid #5f5f5f",
                      backgroundColor: "#000000",
                      borderRadius: "0.25rem",
                      // "& .MuiSelect-select": {
                      //   padding: "10px",
                      // },
                      ".MuiSelect-icon": {
                        color: "#999999",
                      },
                      "& .MuiInputBase-input": {
                        padding: "6px 10px",
                        fontSize: "0.75rem",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        // Dropdown panel style
                        sx: textFieldDropdownMenuPropsStyle,
                      },
                    }}
                  >
                    {/* Dropdown Items */}
                    {supportPanelIssueData.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        sx={{ fontSize: "0.75rem" }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="flex flex-col w-full">
                {selectedIssue === "other-issue" && (
                  <div>
                    <label className="block text-xs text-[#FFFFFF] mb-2">
                      Enter issue title{" "}
                      <span className="text-[#FF0000]">*</span>
                    </label>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="w-full text-xxs pl-[10px] pr-[10px] leading-[2.2rem] rounded bg-[#000000] border-[0.79px] border-[#5f5f5f] placeholder-[#7c7c7c] focus:outline-none"
                      placeholder="Enter your subject"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-xs text-[#FFFFFF] mb-2">
                Please describe your issue{" "}
                <span className="text-[#FF0000]">*</span>
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full text-xxs pl-[10px] pr-[10px] leading-[2.2rem] rounded bg-[#000000] border-[0.79px] border-[#5f5f5f] placeholder-[#7c7c7c] focus:outline-none"
                placeholder="Describe your issue here"
                rows="4"
              ></textarea>
            </div>

            <div className="flex flex-col xs:flex-row gap-2">
              <div className="flex flex-col mb-4 w-full">
                <label className="block text-xs text-[#FFFFFF] mb-2">
                  Attach a file
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  // className="w-full text-xs text-color_grey6 file:mr-4 file:py-[0.4rem] file:px-4 file:rounded-full file:border-0 file:text-xs file:font-semibold file:bg-color_grey6 file:text-color_black hover:file:bg-color_grey4 cursor-pointer"
                  className="flex justify-center items-center w-full text-xxs pl-[0px] pr-[10px] leading-[2.1rem] file:leading-[2.1rem] rounded file:pl-[10px] file:border-none file:pr-[8px] bg-[#000000] border-[0.79px] border-[#5f5f5f] placeholder-[#7c7c7c] focus:outline-none cursor-pointer file:cursor-pointer"
                  accept=".pdf, image/jpeg, image/png"
                />

                {/* {attachment && (
                  <div>
                    {attachment.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(attachment)}
                        alt="Attachment"
                        className="w-50 h-50 rounded border mt-2"
                      />
                    ) : attachment.type === "application/pdf" ? (
                      <embed
                        src={URL.createObjectURL(attachment)}
                        type="application/pdf"
                        width="100%"
                        height="400px"
                      />
                    ) : (
                      <span>Attachment: {attachment.name}</span>
                    )}
                  </div>
                )} */}
              </div>

              <div className="flex flex-col w-full mb-4">
                <label className="block text-xs text-[#FFFFFF] mb-2">
                  Take a screenshot
                </label>
                <button
                  onClick={captureScreenshot}
                  className="flex justify-center items-center w-full text-xxs pl-[10px] pr-[10px] leading-[2.2rem] rounded bg-[#000000] border-[0.79px] border-[#5f5f5f] placeholder-[#7c7c7c] focus:outline-none"
                >
                  <AspectRatioOutlinedIcon />
                  {screenshot ? (
                    <span className="ml-1 text-xxs flex items-center">
                      {screenshot.name}
                    </span>
                  ) : (
                    <span className="ml-1 text-xxs">Capture Screen</span>
                  )}
                </button>
                {/* {screenshot && (
                  <img
                    src={viewScreenshot}
                    alt="Screenshot"
                    className="w-50 h-50 rounded border mt-2"
                  />
                )} */}
              </div>
            </div>
            <div className="flex justify-end gap-[1rem]">
              <Button
                onClick={togglePanel}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#111111",
                  borderRadius: "0.3rem",
                  padding: "5px 10px",
                  "&:hover": {
                    color: "#f70202",
                    backgroundColor: "#111111",
                  },
                }}
              >
                Cancel
              </Button>

              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  backgroundColor: "#999999",
                  height: 25,
                  alignSelf: "center",
                }}
              />

              <Button
                onClick={handleSubmit}
                disabled={
                  !email ||
                  !!emailError ||
                  !description ||
                  !selectedIssue ||
                  (selectedIssue === "other-issue" && !subject)
                }
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#111111",
                  borderRadius: "0.3rem",
                  padding: "5px 10px",
                  "&:hover": {
                    color: "#d7ae83",
                    backgroundColor: "#111111",
                  },
                  // Style for disabled state
                  "&.Mui-disabled": {
                    color: "#999999",
                    backgroundColor: "#555555",
                  },
                }}
                autoFocus
              >
                Send
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SupportPanel;
