import React from "react";
import { styles } from "../styles";

const ComingSoon = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-[2rem]">
      <h1 className={`${styles.subtitleSize} font-bold text-color_grey6`}>
        Coming Soon!
      </h1>
      <p className={`mt-[1rem] ${styles.cardsTitleSize} text-color_grey5`}>
        We are working to get this page ready. Stay tuned!
      </p>

      <div className="flex space-x-1 mt-[2rem]">
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 bg-[#D7AE83] rounded-full animate-bounce`}
            style={{
              animationDelay: `${index * 0.2}s`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ComingSoon;
