import { LeftSidebar, SidebarVTwo, TopNavbar, TopNavbarVTwo } from "./sections";
import { useUser } from "./context/userContext";
import Signin from "./components/signin/signin";
import AllRoutes from "./routes/AllRoutes";
import { ToastNotification } from "./common/toastNotification/toastNotification";
import SupportPanel from "./common/supportPanel/supportPanel";

function App() {
  const { user } = useUser();

  return (
    <>
      <ToastNotification />
      {/* <div className="app">
        <LeftSidebar /> */}
      {/* <SidebarVTwo /> */}
      <main className="content">
        {/* <TopNavbar /> */}
        {/* <TopNavbarVTwo /> */}
        <AllRoutes />
      </main>

      {/* <SupportPanel /> */}
      {/* </div> */}
    </>
  );
}

export default App;
