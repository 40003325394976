// const API_BASE_URL = "https://back.techvaarat.xyz";
const API_BASE_URL = "https://backend.vaarat.org";
// const API_BASE_URL = "http://localhost:8070";

export const endpoints = {
  signin: `${API_BASE_URL}/auth/sign-in`,
  signout: `${API_BASE_URL}/auth/logout`,
  verifyotp: `${API_BASE_URL}/auth/verify-otp`,
  resendotp: `${API_BASE_URL}/auth/resend`,
  attendancepunch: `${API_BASE_URL}/attendance/punch`,
  attendancestatus: `${API_BASE_URL}/attendance/empId`,
  geolocation: `${API_BASE_URL}/location`,
};
