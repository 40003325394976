import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { ClipLoader } from "react-spinners";

const ProtectedRoute = ({ role, children }) => {
  const { user, loading } = useUser();

  // console.log(user);

  if (loading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ClipLoader size={50} color="#D7AE83" />
      </div>
    );
  }

  // Redirect to home if the user is not logged in
  // or does not have the required role
  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
