import React, { useState } from "react";
import { Card, CardContent, Button, Box, useMediaQuery } from "@mui/material";
import { styles } from "../../styles";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import { useNavigate } from "react-router-dom";
import { attendancePageData } from "../../constants/attendancePageData";

const AttendanceList = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [hoveredCard, setHoveredCard] = useState(null);

  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const handleNavigate = (routeName) => {
    navigate(`/attendances/${routeName}`);
  };

  return (
    <div
      className={`grid gap-4 mt-4`}
      style={{
        gridTemplateColumns: isNonMobile
          ? "repeat(auto-fit, minmax(300px, 1fr))"
          : "1fr",
      }}
    >
      {attendancePageData.length === 0 ? (
        <h2 className="font-subtitle text-[1.5rem] text-[#EDF6FF]">
          There are no attendance module!
        </h2>
      ) : (
        attendancePageData.map((attendances, index) => (
          <div
            key={attendances.id}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <Card
              sx={{
                borderRadius: "1rem",
                backgroundColor: "#252525",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "relative",
                transition: "all 0.3s ease",
                cursor: "pointer",
                boxShadow:
                  hoveredCard === index
                    ? "0px 4px 20px rgba(0,0,0,0.2)"
                    : "none",
              }}
            >
              <CardContent className="flex flex-col justify-center">
                <div
                  className={`flex flex-row items-center justify-center font-subtitle text-${styles.cardsTitleSize} py-[2rem] gap-3`}
                >
                  <div>
                    {React.createElement(attendances.icon, {
                      sx: { color: "#D7AE83", fontSize: "2.5rem" },
                    })}
                  </div>

                  <h3>{attendances.attendanceTitle}</h3>
                </div>
              </CardContent>
              {/* Expanded Section */}
              <div
                className="flex justify-center items-center"
                style={{
                  height: hoveredCard === index ? "35px" : "0px",
                  transition: "height 0.3s ease, transform 0.3s ease",
                  backgroundColor: "#D7AE83",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: hoveredCard === index ? "0.5rem" : "0px",
                  overflow: "hidden",
                }}
              >
                <ExpandMoreOutlinedIcon
                  sx={{ color: "#141414", fontSize: "2rem" }}
                />
              </div>
            </Card>

            {/* Subtitle Button Section */}
            <Box
              sx={{
                display: hoveredCard === index ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.5rem",
                marginTop: "1.5rem",
                marginX: "1rem",
              }}
            >
              {attendances.attendanceDetails.map((detail, i) => (
                <Button
                  onClick={() => handleNavigate(detail.routeName)}
                  key={i}
                  variant="contained"
                  sx={{
                    width: "100%",
                    justifyContent: "start",
                    fontSize: "1.1rem",
                    backgroundColor: "#272727",
                    color: "#D7AE83",
                    border: "2px solid #272727",
                    borderRadius: "0.5rem",
                    "&:hover": { border: "2px solid #D7AE83" },
                  }}
                >
                  {detail.subtitle}
                </Button>
              ))}
            </Box>
          </div>
        ))
      )}
    </div>
  );
};

export default AttendanceList;
