import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Webcam from "react-webcam";
import { Button, CircularProgress } from "@mui/material";
import {
  deleteButtonSxStyle,
  punchButtonSxStyle,
  resetButtonSxStyle,
} from "../../styles";
import PunchConfirmation from "./punchConfirmation";
import { geoCodingApiAddress } from "./geoCodingApiAddress";
import { useUser } from "../../context/userContext";
import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastNotification/toastNotification";
import { endpoints } from "../../api/apiConfig";

const PunchAttendance = () => {
  // Webcam and image state
  const webcamRef = React.useRef(null);
  const [isWebcamReady, setIsWebcamReady] = useState(false);
  const isLocationErrorShownRef = useRef(false);
  const [isWebcamErrorShown, setIsWebcamErrorShown] = useState(false);

  const [loading, setLoading] = useState(true);
  const [punch, setPunch] = useState({});

  // dialog-box state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");

  // Dropdown and comment states
  const [selectedReason, setSelectedReason] = useState("");
  const [comment, setComment] = useState("");

  const { user } = useUser();

  // punch location and date,time data
  const [punchData, setPunchData] = useState({
    image: null,
    date: "Fetching...",
    time: "Fetching...",
    address: "Fetching...",
    geoCode: "Fetching...",
    distance: "",
    withinRadius: false,
  });

  // Demo location - sealdah
  const officeCoordinates = useMemo(
    () => ({
      // lat: 22.521721,
      // lng: 88.380913,
      lat: 22.4477,
      lng: 88.4361,
    }),
    []
  );

  // Permissible radius, within 100m the user will be
  // allowed to punch -in and punch - out
  const permissibleRadius = 500;

  // Calculate the distance between user and office
  const calculateDistance = (coords1, coords2) => {
    const R = 6371e3;
    const lat1 = (coords1.lat * Math.PI) / 180;
    const lat2 = (coords2.lat * Math.PI) / 180;
    const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
    const deltaLng = ((coords2.lng - coords1.lng) * Math.PI) / 180;

    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1) *
        Math.cos(lat2) *
        Math.sin(deltaLng / 2) *
        Math.sin(deltaLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in meter
    return R * c;
  };

  // Function to get the current time
  const getCurrentTime = () => {
    const now = new Date();
    return {
      formattedDate: now.toLocaleDateString(),
      formattedTime: now.toLocaleTimeString(),
    };
  };

  // Debounce function to limit address fetching calls
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const updatePunchData = useCallback(() => {
    // For high accuracy
    const highAccuracy = {
      enableHighAccuracy: true,
      // Timeout after 10 seconds
      timeout: 10000,
      // Do not use cached position
      maximumAge: 0,
    };

    const watchId = navigator.geolocation.getCurrentPosition(
      async (position) => {
        const accuracy = position.coords.accuracy;
        const userCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(position);

        // if (accuracy < 50) {
        //   // Process location data as it is accurate enough
        //   console.log("Accurate location:", accuracy);
        // } else {
        //   console.warn("Low accuracy:", accuracy);
        // }

        const distance = calculateDistance(userCoordinates, officeCoordinates);
        const withinRadius = distance <= permissibleRadius;

        // Get the current date and time
        const { formattedDate, formattedTime } = getCurrentTime();
        let address = "Fetching...";

        // Update location data excluding the address
        setPunchData((prevData) => ({
          ...prevData,
          date: formattedDate,
          time: formattedTime,
          geoCode: `${userCoordinates.lat.toFixed(
            6
          )}, ${userCoordinates.lng.toFixed(6)}`,
          distance: distance.toFixed(2),
          withinRadius,
          image: null,
        }));

        // Only update the address
        // after a small delay to avoid excessive API calls
        debounce(async () => {
          try {
            // Fetch the address from the geo-code API
            address = await geoCodingApiAddress(
              userCoordinates.lat,
              userCoordinates.lng
            );
          } catch (error) {
            console.error("Failed to fetch address: ", error);
            address = "Error fetching address.";
          }

          // Update location data including the address
          setPunchData((prevData) => ({
            ...prevData,
            address: address,
          }));
        }, 1000)();

        setLoading(false);
        isLocationErrorShownRef.current = false;
      },
      (error) => {
        console.error("Geolocation error: ", error);
        setLoading(false);
        // Handle the error
        let errorMessage = "Error fetching location. Please check permissions.";
        if (error.code === 3) {
          errorMessage = "Timeout expired. Please refresh the page.";
        }
        // Show error toast only once
        if (!isLocationErrorShownRef.current) {
          showErrorToast(errorMessage);
          isLocationErrorShownRef.current = true;
        }

        setPunchData((prevData) => ({
          ...prevData,
          address: errorMessage,
          geoCode: errorMessage,
        }));
      },
      highAccuracy
    );
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [officeCoordinates]);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const formatTime = (date) => {
    date = date instanceof Date && !NaN(date) ? date : new Date();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  // Punch Status
  const checkPunchStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        `${endpoints.attendancestatus}?empId=${user.empId}`
      );
      // console.log("Response Status:", response);

      if (response?.data) {
        setPunch(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch punch status:", error);
    }
  }, [user?.empId]);

  // console.log(punch);

  useEffect(() => {
    updatePunchData();
    checkPunchStatus();

    const intervalId = setInterval(() => {
      const { formattedDate, formattedTime } = getCurrentTime();
      setPunchData((prevData) => ({
        ...prevData,
        date: formattedDate,
        time: formattedTime,
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [updatePunchData, checkPunchStatus]);

  // Capturing Image
  const captureImage = async () => {
    if (!webcamRef.current) {
      showErrorToast("Webcam is not available. Please check the webcam.");
      return null;
    }

    if (!isWebcamReady) {
      showErrorToast("Camera is not ready yet. Please wait.");
      return null;
    }

    // delay before capturing
    await new Promise((resolve) => setTimeout(resolve, 500));

    // Capture the image
    const image = webcamRef.current.getScreenshot();

    setPunchData((prevData) => ({
      ...prevData,
      image: image,
    }));

    console.log("Captured image successfully", image);
    return image;
  };

  // Handle to punch in and punch out
  const handlePunchAction = async () => {
    // Capture the image before proceeding
    const image = await captureImage();

    // Check if the image is captured
    if (!image) {
      showErrorToast("Image capture failed. Please try again.");
      return;
    }

    const punchDetails = {
      empId: user?.empId,
      date: formatDate(punchData.date),
      punchType:
        punch === undefined
          ? "in"
          : punch.punchType === "in"
          ? "out"
          : punch.punchType === "out"
          ? "in"
          : "",
      punchTime: formatTime(punchData.time),
      location: punchData.address,
      image: image,
      punchGeoCodeLat: parseFloat(punchData.geoCode.split(",")[0]),
      punchGeoCodeLon: parseFloat(punchData.geoCode.split(",")[1]),
      distanceFromOffice: punchData.distance,
      reason: String(selectedReason),
      comment: selectedReason === "others" ? String(comment) : "",
    };
    console.log("Punch details:", punchDetails);

    try {
      const response = await fetch(`${endpoints.attendancepunch}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(punchDetails),
        // body: formData,
      });
      const data = await response.json();

      if (data.status) {
        showSuccessToast(`${data.message}`);
      } else {
        console.error(`Status: ${data.status}`);
      }
    } catch (error) {
      console.error("API call failed:", error);
      showErrorToast("Failed to punch. Try again.");
    }
  };

  const handleDialogConfirm = async () => {
    setLoading(true);

    try {
      await handlePunchAction();
      await checkPunchStatus();

      setDialogOpen(false);
    } catch (error) {
      showErrorToast("Punch action failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleReasonChange = (event) => {
    // setSelectedReason(event.target.value);
    const newReason = event.target.value;
    setSelectedReason(newReason);
    if (newReason !== "others") {
      setComment("");
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Function to handle reset
  const handleReset = () => {
    // Reset all necessary states
    setLoading(true);
    setPunchData({
      image: null,
      date: "Fetching...",
      time: "Fetching...",
      address: "Fetching...",
      geoCode: "Fetching...",
      distance: "",
      withinRadius: false,
    });
    // Restart the location update process
    updatePunchData();
    showSuccessToast(`Resetting Data!`);
  };

  // Close the dialog-box
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <div className="font-subtitle mt-4 w-full max-w-lg mx-auto">
      <div className="bg-color_grey1 p-[1rem] rounded-[1rem] w-full max-w-lg mx-auto">
        {/* Image Section */}
        <div className="flex justify-center mb-[1.5rem]">
          <div className="relative w-full max-w-[250px] h-[250px] rounded-[1.5rem] overflow-hidden">
            <div className="absolute inset-0 rounded-[1.5rem] p-[0.4rem] bg-[#242323] shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <div className="w-full h-full bg-color_grey2 rounded-[1.5rem] overflow-hidden">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  onUserMedia={() => {
                    console.log("Webcam is ready");
                    setIsWebcamReady(true);
                    setIsWebcamErrorShown(false);
                  }}
                  onUserMediaError={() => {
                    if (!isWebcamErrorShown) {
                      showErrorToast(
                        "Error accessing the webcam. Please check permissions."
                      );
                      setIsWebcamErrorShown(true);
                    }
                  }}
                  className="object-cover w-full h-full rounded-[1.5rem]"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Date and Time Section */}
        {/* shadow - [inset x-offset y-offset blur-radius spread-radius color] */}
        <div className="justify-center max-w-xs mx-auto bg-color_grey2 rounded-[1.5rem] p-0 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
          <div className="text-color_white text-base items-center text-center font-medium pt-[0.8rem] pb-[0.5rem]">
            Date and Time
          </div>

          <div className="flex flex-row w-full justify-center">
            <div className="flex w-full justify-center bg-color_grey2 rounded-tl-[1.5rem] rounded-bl-[1.5rem] p-2 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span className="text-color_white font-light text-xs">
                {loading ? "Fetching..." : `${punchData.date}`}
              </span>
            </div>
            <div className="flex w-full justify-center bg-color_grey2 rounded-tr-[1.5rem] rounded-br-[1.5rem] p-2 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span className="text-color_white font-light text-xs">
                {loading ? "Fetching..." : `${punchData.time}`}
              </span>
            </div>
          </div>
        </div>

        {/* Address and Geo-Code Section */}
        <div className="flex flex-row w-full mx-auto mt-[0.5rem]">
          <div className="flex flex-col w-1/2 justify-start pr-[0.5rem]">
            <div className="text-color_white text-base font-medium pt-[0.8rem] pb-[0.5rem]">
              Address
            </div>

            <div className="flex w-full justify-start bg-color_grey2 rounded-[1.5rem] p-2 pl-3 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span
                className={`text-color_white font-light text-xs xs:line-clamp-1 line-clamp-2`}
              >
                {loading ? "Fetching..." : `${punchData.address}`}
              </span>
            </div>
          </div>
          <div className="flex flex-col w-1/2 justify-start pl-[0.5rem]">
            <div className="text-color_white text-base font-medium pt-[0.8rem] pb-[0.5rem]">
              Geo-Code
            </div>

            <div className="flex w-full justify-start bg-color_grey2 rounded-[1.5rem] p-2 pl-3 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span className="text-color_white font-light text-xs xs:line-clamp-1 line-clamp-2">
                {loading ? "Fetching..." : `${punchData.geoCode}`}
              </span>
            </div>
          </div>
        </div>

        {/* Other Section */}
        <div className="flex flex-col w-full mx-auto mt-[3rem] xs:gap-4 gap-6">
          <div className="flex flex-col xs:flex-row w-full justify-start items-center gap-1">
            <div className="text-color_white text-[0.9rem] font-light w-full xs:w-[230px]">
              Your distance from office address:
            </div>

            <div className="flex w-fit min-w-[8rem] p-[0.5rem] justify-center text-center items-center bg-color_grey2 rounded-[2rem] p-2 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span className="text-color_white font-light text-xs">
                {loading ? "Calculating..." : `${punchData.distance} m`}
              </span>
            </div>
          </div>

          <div className="flex flex-col xs:flex-row w-full justify-start items-center gap-1">
            <div className="text-color_white text-[0.9rem] font-light w-full xs:w-[230px]">
              You are within permissible radius:
            </div>

            <div className="flex w-fit min-w-[8rem] p-[0.5rem] justify-center text-center items-center bg-color_grey2 rounded-[2rem] p-2 shadow-[inset_2px_2px_10px_2px_rgba(0,0,0,1),inset_-2px_-2px_4px_0px_rgba(61,54,54,1)]">
              <span
                className={`font-light text-xs ${
                  loading
                    ? "text-color_white"
                    : punchData.withinRadius
                    ? "text-color_green"
                    : "text-color_red"
                } `}
              >
                {loading
                  ? "Calculating..."
                  : `${punchData.withinRadius ? "Yes" : "No"}`}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="flex flex-row justify-center gap-[2rem] mt-[4rem] mb-[4rem]">
        <Button
          sx={resetButtonSxStyle}
          onClick={() => {
            handleReset();
          }}
        >
          Reset
        </Button>

        <Button
          sx={punchButtonSxStyle}
          onClick={() => {
            setDialogType(punch?.punchType === "in" ? "punchOut" : "punchIn");
            setDialogOpen(true);
          }}
          disabled={
            loading || punchData.distance === "" || !user?.empId
            // || !punchData.withinRadius
          }
        >
          {loading
            ? "Checking..."
            : punch === undefined
            ? "Punch In"
            : punch?.punchType === "in"
            ? "Punch Out"
            : punch?.punchType === "out"
            ? "Punch In"
            : "Checking..."}

          {/* {loading && "Checking..."} */}
        </Button>

        {/* Confirmation Dialog */}
        <PunchConfirmation
          open={dialogOpen}
          loading={loading}
          handleClose={handleDialogClose}
          handleConfirm={handleDialogConfirm}
          title={
            dialogType === "punchIn" ? "Confirm Punch-In" : "Confirm Punch-Out"
          }
          message={`Are you sure you want to ${
            dialogType === "punchIn" ? "punch in" : "punch out"
          }?`}
          punchData={punchData}
          type={dialogType}
          withinRadius={punchData.withinRadius}
          selectedReason={selectedReason}
          comment={comment}
          onReasonChange={handleReasonChange}
          onCommentChange={handleCommentChange}
          isConfirmDisabled={
            !punchData.withinRadius && (!selectedReason || !comment)
          }
        />
      </div>
    </div>
  );
};

export default PunchAttendance;
