import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useUser } from "../../context/userContext";
import {
  DataGrid,
  GridExpandMoreIcon,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        /> */}
      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          backgroundColor: "#111111",
          fontSize: "1rem",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: {
              variant: "outlined",

              sx: {
                color: "#D7AE83",
                fontSize: "12px",
                fontWeight: "500",
                border: "1px solid #111111",
                borderRadius: "5px",
                padding: "6px 12px",
                "&:hover": {
                  color: "#EDF6FF",
                  border: "1px solid #111111",
                },
              },
            },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
}

const AttendanceDetailsDialog = ({ open, onClose, attendanceDetails }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (attendanceDetails) {
      setLoading(false);
    }
  }, [attendanceDetails]);

  if (!attendanceDetails) return null;

  const columns = [
    {
      field: "empId",
      headerName: "Employee ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "empFirstName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-cell",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "sessionId",
      headerName: "Session ID",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "punchType",
      headerName: "Punch Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.value === "in" ? "#d7ae83" : "#ff0000",
            fontWeight: "bold",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "punchTime",
      headerName: "Punch Time",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalHoursWorked",
      headerName: "Worked Hours",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1.5,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "attendancePhoto",
      headerName: "Attendance Photo",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={params.value}
            alt="Attendance"
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              overflow: "hidden",
              cursor: "pointer",
              objectFit: "contain",
              backgroundColor: "#999999",
            }}
            // onClick={() => handleClickOpen(params.value)}
          />
        </div>
      ),
      headerAlign: "left",
      align: "left",
      // Add this flag to exclude the image column during export
      disableExport: true,
    },

    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
    },
  ];

  const rows = attendanceDetails.punchDetails.map((punch, index) => ({
    id: index + 1,
    empId: attendanceDetails.empId,
    empFirstName: attendanceDetails.empFirstName,
    date: attendanceDetails.date,
    sessionId: punch.sessionId,
    punchType: punch.punchType,
    punchTime: punch.punchTime,
    totalHoursWorked: punch.totalHoursWorked || "N/A",
    address: punch.address,
    reason: punch.reason || "N/A",
    comment: punch.comment || "N/A",
    attendancePhoto: punch.attendancePhoto,
    remarks: punch.remarks || "N/A",
  }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#333333",
          borderRadius: "20px",
          boxShadow: "none",
          position: "relative",
        },
      }}
    >
      <DialogTitle sx={{ color: "#ffffff" }}>
        Attendance Details
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#ffffff",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        style={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box mb={2} color="#ffffff">
          <Typography variant="h6">Date: {attendanceDetails.date}</Typography>
          <Typography variant="subtitle1">
            Employee: {attendanceDetails.empFirstName}{" "}
            {attendanceDetails.empLastName}
          </Typography>
        </Box>

        <Box
          m="2rem 0rem 0rem 0rem"
          height="100%"
          minHeight="80vh"
          width="100%"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "12px",
              overflow: "hidden",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-cell": {
              color: "#d7ae83",
            },
            "& .id-cell": {
              color: "#d7ae83",
            },
            "& .role-cell": {
              color: "#d7ae83",
            },
            "& .status-cell": {
              color: "#d7ae83",
            },
            // Header Background Color
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#333333 !important",
            },

            // Header Title Color
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#111111 !important",
            },

            // Header Title Separator Color
            "& .MuiDataGrid-columnSeparator": {
              // visibility: "hidden",
              color: "#999999 !important",
            },

            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: "#252525",
              color: "#EDF6FF",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#EDF6FF",
            },
            "& .MuiDataGrid-toolbarContainer": {
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "#e6ccb1",
              padding: "8px",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: "#111111",
            },

            // Checkbox Color
            "& .MuiCheckbox-root": {
              color: "#e6ccb1",
            },
            // After Checked Color
            "& .MuiCheckbox-root.Mui-checked": {
              color: "#e6ccb1",
            },
            "& .MuiCheckbox-root.MuiCheckbox-indeterminate": {
              color: "#e6ccb1",
              // backgroundColor: "#000000",
            },

            // Remove focus outline
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
              outline: "none !important",
            },
            // Remove row selection highlight
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#505c66 !important",
            },
          }}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: "#d7ae83",
                position: "absolute",
                top: "30%",
                left: "50%",
              }}
            />
          ) : (
            <DataGrid
              // rows={attendanceData}
              rows={rows}
              columns={columns}
              checkboxSelection
              // onCellClick={handleCheckboxClick}
              disableSelectionOnClick
              // {...assetsData}
              slots={{
                toolbar: CustomToolbar,
              }}
              sx={{
                width: "100%",
                minWidth: "850px",
                overflowX: "auto",
              }}
            />
          )}
        </Box>

        {/* {attendanceDetails ? (
          <div style={{ color: "#EDF6FF", textAlign: "center" }}>
            <h3>Attendance Details for {attendanceDetails.empName}</h3>

            <p>
              <strong>Employee ID:</strong> {attendanceDetails.empId}
            </p>
            <p>
              <strong>Attendance Photo:</strong>
            </p>
            <img
              src={attendanceDetails.attendancePhoto}
              alt="Attendance"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "5px",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          <p>No data available</p>
        )} */}
      </DialogContent>
    </Dialog>
  );
};

export default AttendanceDetailsDialog;
