import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { outOfWithinRadiusData } from "../../constants/punchAttendanceData";
import {
  textFieldDropdownMenuPropsStyle,
  textFieldDropdownSxStyle,
  textFieldInputLabelStyle,
  textFieldInputStyle,
  textFieldSxStyle,
} from "../../styles";

const PunchConfirmation = ({
  open,
  handleClose,
  handleConfirm,
  title,
  message,
  withinRadius,
  selectedReason,
  comment,
  onReasonChange,
  onCommentChange,
  isConfirmDisabled,
  loading,
}) => {
  const isOtherSelected = selectedReason === "others";

  // Disable confirm button logic
  const isConfirmButtonDisabled = () => {
    if (withinRadius) {
      return false;
    }
    if (!selectedReason) {
      return true;
    }
    if (selectedReason === "others" && !comment) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: "#333333",
          borderRadius: "1rem",
          padding: "2rem",
          display: "flex",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "#ffffff",
          padding: "0px",
          marginBottom: "1.5rem",
          fontSize: "1.2rem",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: "0px", marginBottom: "1.5rem" }}>
        <DialogContentText
          sx={{ color: "#ffffff !important", fontSize: "1rem" }}
        >
          {message}
        </DialogContentText>

        {!withinRadius && (
          <div className="mt-[1rem]">
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel
                style={{ color: "#999999", fontSize: "1rem" }}
                id="reason-label"
              >
                Reason
              </InputLabel>
              <Select
                labelId="reason-label"
                value={selectedReason}
                onChange={onReasonChange}
                style={textFieldInputStyle}
                sx={textFieldDropdownSxStyle}
                MenuProps={{
                  PaperProps: {
                    // Dropdown panel style
                    sx: textFieldDropdownMenuPropsStyle,
                  },
                }}
              >
                {/* <MenuItem value="" disabled>
                        Select One
                      </MenuItem> */}
                {outOfWithinRadiusData.map((reason) => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {isOtherSelected && (
              <TextField
                fullWidth
                size="small"
                variant="filled"
                label="Comments"
                value={comment}
                onChange={onCommentChange}
                margin="normal"
                multiline
                rows={3}
                slotProps={{
                  input: {
                    style: textFieldInputStyle,
                  },
                  inputLabel: {
                    style: textFieldInputLabelStyle,
                  },
                }}
                sx={textFieldSxStyle}
              />
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions className="gap-2" sx={{ padding: "4px 0px" }}>
        <Button
          onClick={handleClose}
          sx={{
            fontSize: "0.9rem",
            color: "#ffffff",
            backgroundColor: "#212121",
            borderRadius: "0.3rem",
            padding: "5px 10px",
            "&:hover": {
              color: "#f70202",
              backgroundColor: "#111111",
            },
          }}
        >
          Cancel
        </Button>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "#999999",
            height: 25,
            alignSelf: "center",
          }}
        />

        <Button
          onClick={handleConfirm}
          sx={{
            fontSize: "0.9rem",
            color: "#EDF6FF",
            backgroundColor: "#212121",
            borderRadius: "0.3rem",
            padding: "5px 10px",
            "&:hover": {
              color: "#D7AE83",
              backgroundColor: "#111111",
            },
            // Style for disabled state
            "&.Mui-disabled": {
              color: "#999999",
              backgroundColor: "#555555",
            },
          }}
          autoFocus
          disabled={isConfirmButtonDisabled()}
        >
          {loading ? (
            <CircularProgress size={20} style={{ color: "#D7AE83" }} />
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PunchConfirmation;
