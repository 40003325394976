import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../context/userContext";
import { IntroLogoOne, IntroLogoThree, IntroLogoTwo, Logo } from "../../assets";
import CustomDialogbox from "../../common/dialogbox/customDialogbox";
import { Button, CircularProgress, IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  showErrorToast,
  showSuccessToast,
} from "../../common/toastNotification/toastNotification";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../api/apiConfig";
import { maskEmail } from "./maskEmail";

const Signin = () => {
  const navigate = useNavigate();

  const { handleSignin, handleSignout } = useUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // OTP State
  const [isOtpDialogOpen, setIsOtpDialogOpen] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(300);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const [error, setError] = useState("");

  // Loader State
  const [loading, setLoading] = useState(false);

  // Masking email id
  const maskedEmail = maskEmail(email);

  // Handling video playback
  const videoRef = useRef(null);

  const [currentVideo, setCurrentVideo] = useState(IntroLogoOne);

  // Function to handle video end
  const handleVideoEnd = () => {
    setCurrentVideo(
      (prevVideo) => (prevVideo === IntroLogoOne ? IntroLogoTwo : IntroLogoOne)
      // prevVideo === IntroLogoOne ? IntroLogoThree : IntroLogoOne
    );
  };

  // For managing input references
  const inputRefs = useRef([]);

  // Timer for resend OTP
  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      // Enable resend button when timer reaches zero
      setIsResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  // Handle change for employee email and code input field
  const handleEmailCodeChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    setError("");

    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidCode = /^\d{8}$/;

    if (value && !isValidEmail.test(value) && !isValidCode.test(value)) {
      setError("Invalid email or employee code");
    }
  };

  // API CALLING
  const handleSignIn = async () => {
    setLoading(true);

    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isValidCode = /^\d{8}$/;

    let signinRequest = {};

    if (isValidEmail.test(email)) {
      signinRequest = { empEmail: email.toLowerCase(), empPassword: password };
    } else if (isValidCode.test(email)) {
      signinRequest = {
        empEmail: email.toString(),
        empPassword: password,
      };
    } else {
      showErrorToast("Please enter a valid email or employee code.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${endpoints.signin}`, signinRequest, {
        headers: { "Content-Type": "application/json" },
        withCredentials: "true",
      });

      if (response.data.status) {
        // Store user data and tokens in local storage
        const userData = response.data.user;
        console.log("User data received:", userData);
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        // Store the OTP sent from the backend
        setGeneratedOtp(response.data.otp);
        setIsOtpDialogOpen(true);
        setTimer(300);
        setIsResendDisabled(true);
        setOtp(Array(6).fill(""));
        showSuccessToast("OTP has been sent to your email!");
      } else {
        // Show error if status is false
        showErrorToast(response.data.message || "Sign-in failed.");
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        showErrorToast(error.response?.data?.message || "Sign-in failed.");
      } else {
        showErrorToast(error.message || "Sign-in failed. Please try again.");
      }

      // showErrorToast(error.response?.data?.message || "Sign-in failed. Please try again.");
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  // Handle resend OTP button
  const handleResendOtp = async () => {
    try {
      // Retrieve user data from localStorage
      const user = JSON.parse(localStorage.getItem("user"));

      if (!user) {
        showErrorToast("User data not found.");
        return;
      }

      // Make API call to resend OTP
      const response = await axios.post(
        `${endpoints.resendotp}`,
        {
          empId: user?.empId,
          empWorkEmail: user?.empWorkEmail,
          name: `${user?.empFirstName || ""} ${user?.empLastName || ""}`.trim(),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Check if the response is successful
      if (response.data.status) {
        showSuccessToast("OTP has been resent to your email.");
        setGeneratedOtp(response.data.otp);
        setTimer(300);
        setIsResendDisabled(true);
        setOtp(Array(6).fill(""));
      } else {
        showErrorToast(response.data.message || "Failed to resend OTP.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      showErrorToast(error.response?.data?.message || "Error resending OTP.");
    }
  };

  // Handle OTP submission with API call
  const handleOtpSubmit = async () => {
    setLoading(true);

    // Convert the email to lowercase for case-insensitive comparison
    const normalizedEmail = email.toLowerCase();

    // Retrieve user data from local storage
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    const empID = user ? user.empId : "";

    try {
      const response = await axios.post(`${endpoints.verifyotp}`, {
        // empEmail: normalizedEmail,
        empID: empID.toString(),
        otp: otp.join(""),
      });

      if (response.data.status) {
        navigate("/attendances");

        handleSignin(user, accessToken, refreshToken);
        showSuccessToast("You're Signed In!");
        setIsOtpDialogOpen(false);
        setOtp(Array(6).fill(""));
      } else {
        // OTP verification failed, clear stored data and show error
        handleSignout();
        showErrorToast(response.data.message || "Invalid OTP.");
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    } catch (error) {
      showErrorToast(
        error.response?.data?.message || "OTP verification failed."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseOtpDialog = async () => {
    setIsOtpDialogOpen(false);
    setOtp(Array(6).fill(""));

    try {
      // Use the normalized email
      const normalizedEmail = email.toLowerCase();

      // Retrieve user data from local storage
      const user = JSON.parse(localStorage.getItem("user"));
      const empID = user ? user.empId : "";

      console.log("User data from localStorage:", user);
      console.log("Employee ID:", empID);

      // Call the logout API using DELETE method
      const response = await axios.delete(`${endpoints.signout}`, {
        data: {
          // empEmail: normalizedEmail,
          empID: empID.toString(),
          // action: "logout",
        },
      });

      console.log("Sign out response:", response.data);
      showSuccessToast(response.data || "Signed Out Successful!");
      // Clear user data from local storage
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      // handleSignout();
    } catch (error) {
      console.error("Sign out failed:", error);
      showErrorToast(
        error.response?.data?.message || "Sign out failed. Please try again."
      );
    }
  };

  useEffect(() => {
    // If page is refreshed while OTP is open, clear localStorage
    if (isOtpDialogOpen) {
      window.addEventListener("beforeunload", handleSignout);
    } else {
      window.removeEventListener("beforeunload", handleSignout);
    }
    return () => window.removeEventListener("beforeunload", handleSignout);
  }, [isOtpDialogOpen, handleSignout]);

  useEffect(() => {
    // Function to handle logout API call on page refresh
    const handlePageRefreshLogout = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const empID = user ? user.empId : "";

        // Check if user is still present in localStorage before logout
        if (empID) {
          const response = await axios.delete(`${endpoints.signout}`, {
            data: { empID: empID.toString() },
          });

          // Clear localStorage data
          console.log("Logout response:", response.data);
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          handleSignout();
        }
      } catch (error) {
        console.error("Error logging out:", error);
      }
    };

    // Add event listener for page refresh
    if (isOtpDialogOpen) {
      window.addEventListener("beforeunload", handlePageRefreshLogout);
    }

    // Clean up event listener on unmount or when OTP dialog closes
    return () =>
      window.removeEventListener("beforeunload", handlePageRefreshLogout);
  }, [isOtpDialogOpen, handleSignout]);

  // OTP section
  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.replace(/\D/g, "").slice(0, 1);
    setOtp(newOtp);

    // Move to the next input field
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    // Check if all fields are filled and confirm
    if (newOtp.every((digit) => digit) && index === 6) {
      handleOtpSubmit();
    }
  };

  // Handling the sign in button
  const handleSignInKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  const handleKeyDown = (index, event) => {
    if (!event || !event.key) return;

    // Move to the next input on Enter key press
    if (event.key === "Enter") {
      if (index === 5) {
        handleOtpSubmit();
      } else {
        inputRefs.current[index + 1].focus();
      }
    }

    // Handle Backspace key press
    if (event.key === "Backspace") {
      if (otp[index] === "") {
        // Move to the previous input if current is empty
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        // Clear the current input
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
      // Prevent the default backspace behavior
      event.preventDefault();
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <video
        ref={videoRef}
        src={currentVideo}
        autoPlay
        muted
        loop={false}
        onEnded={handleVideoEnd}
        className="absolute inset-0 w-full h-full object-contain z-[-1] bg-[#000000] opacity-20"
      />
      <div className="flex items-center justify-center min-h-screen p-[1rem]">
        {/* <div
          className="absolute inset-0 bg-[#000000] opacity-10"
          // style={{
          //   backgroundImage: `url(${Logo})`,
          //   backgroundSize: "contain",
          //   backgroundPosition: "center",
          //   backgroundRepeat: "no-repeat",
          //   opacity: 0.05,
          //   zIndex: -1,
          // }}
        ></div> */}

        <div className="relative bg-transparent w-full max-w-md mx-auto rounded-xl p-4">
          <div className="flex justify-center mb-4 font-title text-xl text-color_white">
            <h2>Sign In</h2>
          </div>

          <div className="flex flex-col gap-4 text-color_white text-xs">
            <input
              type="text"
              placeholder="Enter Email / Employee Code"
              value={email}
              onChange={handleEmailCodeChange}
              onKeyDown={handleSignInKeyDown}
              className="w-full p-2 rounded-md border-2 border-color_gold bg-transparent focus:border-color_gold outline-none placeholder-color_grey5"
            />
            {error && (
              <p
                style={{
                  color: "#f70202",
                  fontSize: "12px",
                  padding: "0px 5px 0px 8px",
                }}
              >
                {error}
              </p>
            )}

            <div className="relative">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleSignInKeyDown}
                className="w-full p-2 rounded-md border-2 border-color_gold bg-transparent focus:border-color_gold outline-none placeholder-color_grey5"
              />
              <span className="absolute inset-y-0 right-2 flex items-center cursor-pointer">
                <IconButton
                  onClick={togglePasswordVisibility}
                  style={{ backgroundColor: "transparent" }}
                  edge="end"
                  size="small"
                >
                  {isPasswordVisible ? (
                    <VisibilityOffOutlinedIcon style={{ color: "#D7AE83" }} />
                  ) : (
                    <VisibilityOutlinedIcon style={{ color: "#D7AE83" }} />
                  )}
                </IconButton>
              </span>
            </div>

            <button
              onClick={handleSignIn}
              className="mt-4 px-4 py-2 bg-transparent border-2 border-color_gold hover:border-color_gold w-full rounded-md text-color_white text-xs"
              disabled={loading}
            >
              {/* Signin */}

              {loading ? (
                <CircularProgress size={18} style={{ color: "#D7AE83" }} />
              ) : (
                "Sign In"
              )}
            </button>
          </div>

          <CustomDialogbox
            open={isOtpDialogOpen}
            handleClose={() => {
              handleCloseOtpDialog();
              setOtp(Array(6).fill(""));
              setTimer(300);
              setIsResendDisabled(true);
            }}
            handleConfirm={handleOtpSubmit}
            title="OTP Verification"
            message={`Please enter the OTP sent to your email: ${maskedEmail}`}
            buttonText={
              loading ? (
                <CircularProgress size={20} style={{ color: "#D7AE83" }} />
              ) : (
                "Verify OTP"
              )
            }
            disabled={!otp.every((digit) => digit)}
            disableClose={true}
          >
            <div className="flex flex-col items-center justify-center w-full m-auto space-y-6 font-subtitle">
              <div className="flex space-x-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    inputMode="numeric"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    maxLength={1}
                    // shadow - [inset x-offset y-offset blur-radius spread-radius color]
                    className="sm:w-12 sm:h-12 w-9 h-9 bg-[#242323] text-color_white text-center rounded-lg focus:outline-none focus:ring-2 focus:ring-color_gold shadow-[inset_5px_5px_5px_0px_rgba(0,0,0,1),inset_-2px_-2px_5px_2px_rgba(42,41,44,1)]"
                  />
                ))}
              </div>

              <div className="flex flex-col justify-center items-center">
                <p className="text-color_grey6 font-light mb-[0.1rem]">
                  Didn't receive any OTP?
                </p>
                <div className="flex flex-row justify-end w-full items-center text-center text-color_white text-xs gap-2">
                  <Button
                    onClick={handleResendOtp}
                    disabled={isResendDisabled}
                    sx={{
                      color: "#d7ae83",
                      // backgroundColor: "#212121",
                      // borderRadius: "0.3rem",
                      padding: "4px 0px 0px 0px",
                      "&:hover": {
                        color: "#13f702",
                        backgroundColor: "transparent",
                      },
                      "&.Mui-disabled": {
                        color: "#999999",
                        // backgroundColor: "#555555",
                      },
                    }}
                  >
                    Resend OTP
                    {/* {isResendDisabled ? `(${timer}s)` : ""} */}
                  </Button>

                  <p className="text-color_grey6 pt-[2px]">
                    after {String(Math.floor(timer / 60)).padStart(2, "0")}:
                    {String(timer % 60).padStart(2, "0")} seconds
                  </p>
                </div>
              </div>
            </div>
          </CustomDialogbox>
        </div>
      </div>
    </>
  );
};

export default Signin;
