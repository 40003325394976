import React, { useEffect, useState } from "react";
import { Logo } from "../assets";
import "./sections.css";
import { styles } from "../styles";
import { useUser } from "../context/userContext";
import { useLocation, useNavigate } from "react-router-dom";

const TopNavbar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [title, setTitle] = useState("Dashboard");

  const { user, handleSignout } = useUser();

  // To go home screen
  const handleLogoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    // Map specific pathnames to more user-friendly titles
    const getTitleFromPath = (path) => {
      const pathMap = {
        "/": "Dashboard",
        "/attendances": "Attendance",
      };

      // If there's a mapped path, return it
      if (pathMap[path]) {
        return pathMap[path];
      }

      // Dynamically extract the last segment of the path if not in the map
      // Split and remove empty segments
      const pathSegments = path.split("/").filter(Boolean);
      if (pathSegments.length > 1) {
        return (
          pathSegments[pathSegments.length - 1]
            // Add spaces before capital letters
            .replace(/([A-Z])/g, " $1")
            // Capitalize the first letter
            .replace(/^./, (str) => str.toUpperCase())
        );
      }

      // Default to Coming Soon! if no matching path
      return "Coming Soon!";
    };

    // Update the title based on the current pathname
    const currentPathTitle = getTitleFromPath(location.pathname);
    setTitle(currentPathTitle);
    document.title = currentPathTitle;
  }, [location.pathname]);

  return (
    <div className="top_navbar_color flex flex-row justify-start items-center relative px-5 h-[4.5rem]">
      <div className={`flex font-title text-${styles.titleSize}`}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default TopNavbar;
