import Dashboard from "./dashboardPage/dashboard";
import AttendancePage from "./attendancePage/attendancePage";
import AttendanceList from "./attendancePage/attendanceList";
import AttendanceDashboard from "./attendancePage/attendanceDashboard";
import AttendanceRegularizationPage from "./attendancePage/attendanceRegularizationPage";

export {
  Dashboard,
  AttendancePage,
  AttendanceList,
  AttendanceDashboard,
  AttendanceRegularizationPage,
};
