import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";

import "./sections.css";
import { useUser } from "../context/userContext";

import { LogoFinal } from "../assets";
import SearchBar from "./searchBar";
import { showSuccessToast } from "../common/toastNotification/toastNotification";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isCollapsed,
  onClick,
}) => {
  return (
    <div className="px-[1rem]">
      <MenuItem
        active={selected === title}
        onClick={() => {
          if (onClick) onClick();
          setSelected(title);
        }}
        icon={icon}
      >
        {!isCollapsed && <div>{title}</div>}
        {/* <div>{title}</div> */}

        <Link to={to} />
      </MenuItem>
    </div>
  );
};

const LeftSidebar = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  // get the initial data from local storage
  const { user, handleSignout } = useUser();

  const isExtraSmall = useMediaQuery("(max-width:600px)");

  const handleResize = () => {
    // Collapse when screen width is less than 768
    // (using the 'md' breakpoint) => tailwind configuration
    if (window.innerWidth < 768) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  };

  // To go home screen
  // const handleProfilePhotoClick = () => {
  //   navigate("/settings/profileSettings");
  // };

  // Dialog Box Section
  // Open the confirmation dialog
  const handleSignOutClick = () => {
    setOpenDialog(true);
  };

  // Close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // Confirm sign-out and navigate to home
  const handleSignOutConfirm = () => {
    handleSignout();
    navigate("/");
    setOpenDialog(false);
    showSuccessToast("You're Signed Out!");
  };

  // To go home screen
  const handleLogoClick = () => {
    navigate("/attendances");
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  // Update the selected state based on the current URL path
  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      // case "/":
      //   setSelected("Dashboard");
      //   break;

      case "/attendances":
        setSelected("Attendance");
        break;

      default:
        if (path.includes("/attendances")) {
          setSelected("Attendance");
        }
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // To handle clicking outside sidebar
  const handleOverlayClick = () => setIsCollapsed(true);

  const marginLeftValue = isCollapsed
    ? "-1.1rem !important"
    : "-0.7rem !important";

  const hoverBackground = isCollapsed
    ? "transparent !important"
    : "rgba(215, 174, 131, 0.1)";

  const activeBackground = isCollapsed
    ? "transparent !important"
    : "rgba(215, 174, 131, 0.2)";

  const iconWrapperActiveBackground = isCollapsed
    ? "rgba(215, 174, 131, 0.2)"
    : "transparent";

  return (
    <Box
      sx={{
        position: isExtraSmall && !isCollapsed ? "absolute" : "relative",
        // left: isExtraSmall && !isCollapsed ? 0 : "initial",
        width: isExtraSmall && isCollapsed ? "4.5rem" : "auto",
        zIndex: isExtraSmall && !isCollapsed ? 1200 : "auto",
        // height: "100vh",
        height: isExtraSmall ? "100vh" : "auto",
        transition: "0.3s ease",
        // transform:
        //   isExtraSmall && isCollapsed ? "translateX(-100%)" : "translateX(0)",

        "& .pro-sidebar-inner": {
          background: "#212121 !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
          marginLeft: marginLeftValue,
          borderRadius: "0.3rem !important",
        },
        "& .pro-inner-item": {
          // border: "transparent 2px solid",
          color: "#ffffff",
          padding: "1rem",
          marginBottom: "0.5rem",
          height: "2.5rem",
          transition: "0.3s ease",
        },
        "& .pro-inner-item:hover": {
          color: "#ffffff !important",
          // borderLeft: "#D7AE83 6px solid",
          // background: "rgba(215, 174, 131, 0.1)",
          background: hoverBackground,
          borderRadius: "0.3rem !important",
        },
        // Remove hover effect on active items
        "& .pro-menu-item.active .pro-inner-item": {
          // color: "#D7AE83 !important",
          // background: "rgba(215, 174, 131, 0.2) !important",
          background: activeBackground,
          borderRadius: "0.3rem !important",
          borderLeft: "transparent 0px solid !important",
          "&:hover": {
            // color: "#D7AE83 !important",
            // background: "rgba(215, 174, 131, 0.2) !important",
            background: activeBackground,
            borderLeft: "transparent 0px solid !important",
          },
        },
        ...(isCollapsed && {
          "& .pro-inner-item:hover .pro-icon-wrapper": {
            background: "rgba(215, 174, 131, 0.1) !important",
          },
          "& .pro-menu-item.active .pro-icon-wrapper": {
            backgroundColor: iconWrapperActiveBackground + " !important",
          },
        }),
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        collapsedWidth="4.5rem"
        width={"17rem"}
        style={{ height: "100%" }}
      >
        <Menu icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}>
          <div className="flex flex-row justify-between items-center w-full mx-auto">
            {!isCollapsed && (
              <div className="flex justify-center items-center mx-[1rem] gap-3">
                <img
                  src={LogoFinal}
                  alt="logo"
                  onClick={handleLogoClick}
                  className="w-[4rem] h-[4rem] object-contain cursor-pointer"
                />
                <h3 className="flex test-center font-title text-base font-medium text-color_white">
                  HRMS
                </h3>
              </div>
            )}
            <div className="flex justify-center items-center mx-[1rem]">
              <IconButton
                style={{
                  color: "#ffffff",
                  height: "2.5rem",
                  width: "2.5rem",
                }}
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <MenuOutlinedIcon />
              </IconButton>
            </div>
          </div>
        </Menu>

        {/* Search bar section */}
        <div>{!isCollapsed && <SearchBar />}</div>

        {/* Menu items section */}
        <Menu iconShape="square" className="flex flex-col h-full">
          <div className="flex-grow">
            <div
              className={`${
                isCollapsed ? "" : "pl-0 items-center"
              } font-subtitle text-xs`}
            >
              <>
                {/* <Item
                  title="Dashboard"
                  to="/"
                  icon={<DashboardOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isCollapsed={isCollapsed}
                /> */}

                <Item
                  title="Attendance"
                  to="/attendances"
                  icon={<HowToRegOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  isCollapsed={isCollapsed}
                />
              </>
            </div>
          </div>
        </Menu>
        <div style={{ marginTop: "auto", padding: "1rem" }}>
          <div
            style={{
              height: "1px",
              backgroundColor: "#999999",
              opacity: "50%",
              width: "100%",
            }}
          ></div>
          <div
            className={`flex ${
              isCollapsed
                ? "flex-col justify-center"
                : "flex-row justify-between"
            } gap-2 items-center mt-[0.6rem] mb-[0.6rem] w-full mx-auto`}
          >
            <div className={`flex flex-row justify-start items-center gap-2`}>
              {user?.empPhoto ? (
                <img
                  src={user.empPhoto}
                  alt="user-photo"
                  // onClick={handleProfilePhotoClick}
                  className={`${
                    isCollapsed ? "h-[40px] w-[40px]" : "h-[40px] w-[40px]"
                  } rounded-[50%] cursor-pointer border-[2px] border-color_gold`}
                />
              ) : (
                <div
                  style={{
                    // backgroundColor: generateRandomColor(),
                    backgroundColor: "#111111",
                    height: isCollapsed ? "40px" : "40px",
                    width: isCollapsed ? "40px" : "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    border: "2px solid #D7AE83",
                    color: "#D7AE83",
                    fontSize: isCollapsed ? "1rem" : "1.4rem",
                    fontWeight: "bold",
                    ineHeight: isCollapsed ? "1" : "1.2",
                    padding: isCollapsed ? "0" : "2px",
                  }}
                >
                  {getInitials(user?.empFirstName, user?.empLastName)}
                </div>
              )}

              {!isCollapsed && (
                <div className="flex flex-col justify-center text-left items-start max-w-[95px]">
                  <h3
                    className={`font-subtitle text-xxs font-medium text-color_white max-w-full`}
                  >
                    {user?.empFirstName && user?.empLastName
                      ? `${user?.empFirstName} ${user?.empLastName}`
                      : user?.empFirstName
                      ? user?.empFirstName
                      : user?.empLastName
                      ? user?.empLastName
                      : "User"}
                  </h3>
                  {/* <p className="font-subtitle text-xxs font-light truncate overflow-hidden text-ellipsis text-color_grey6 max-w-ful">
                    {user?.empRole}
                  </p> */}
                  <p className="font-subtitle text-xxs font-base truncate overflow-hidden text-ellipsis text-color_grey6 max-w-full">
                    {user?.empWorkEmail}
                  </p>
                </div>
              )}
            </div>

            <div className="flex justify-center items-center">
              <IconButton
                onClick={handleSignOutClick}
                sx={{
                  color: "#c8c8c8",
                  // boxShadow: "0px 0px 10px #D7AE83",
                  "&:hover": {
                    // boxShadow: "0px 0px 10px #D7AE83",
                    backgroundColor: "#D7AE83",
                    color: "#141414",
                  },
                  borderRadius: "50%",
                  transition: "0.3s ease",
                }}
              >
                <LogoutOutlinedIcon />
              </IconButton>
            </div>
          </div>

          {/* Divider with shadow */}
          {!isCollapsed && (
            <div
              style={{
                height: "1px",
                backgroundColor: "#999999",
                opacity: "50%",
                width: "100%",
              }}
            ></div>
          )}
          {!isCollapsed && (
            <div className="flex flex-col justify-start w-full mx-auto text-xxs text-color_grey5 mt-[0.6rem]">
              <p>App Version: 1.0.1.2</p>
              <p>Last Published - 13 Nov. 24</p>
            </div>
          )}
        </div>
      </ProSidebar>

      {/* Overlay for Mobile */}
      {isExtraSmall && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: isCollapsed ? "4.5rem" : "17rem",
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1100,
            display: { xs: "block", md: "none" },
            // transition: "0.3s ease",
            opacity: isCollapsed ? 0 : 1,
            transition: "left 0.3s ease, opacity 0.3s ease",
            pointerEvents: isCollapsed ? "none" : "auto",
          }}
          onClick={handleOverlayClick}
        />
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        // onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: "#333333",
            borderRadius: "1rem",
            padding: "2rem",
            display: "flex",
          },
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          sx={{
            color: "#EDF6FF",
            padding: "0px",
            marginBottom: "1.5rem",
          }}
        >
          Confirm Sign Out
        </DialogTitle>
        <DialogContent sx={{ padding: "0px", marginBottom: "1.5rem" }}>
          <DialogContentText sx={{ color: "#EDF6FF !important" }}>
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="gap-2" sx={{ padding: "4px 0px" }}>
          <Button
            onClick={handleCloseDialog}
            sx={{
              color: "#EDF6FF",
              backgroundColor: "#212121",
              borderRadius: "5px",
              padding: "5px 10px",
              "&:hover": {
                color: "#D7AE83",
                backgroundColor: "#111111",
                borderRadius: "5px",
              },
            }}
          >
            Cancel
          </Button>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "#999999",
              height: 25,
              alignSelf: "center",
            }}
          />

          <Button
            onClick={handleSignOutConfirm}
            sx={{
              color: "#EDF6FF",
              backgroundColor: "#212121",
              borderRadius: "5px",
              padding: "5px 10px",
              "&:hover": {
                color: "#f70202",
                backgroundColor: "#111111",
                borderRadius: "5px",
              },
            }}
            autoFocus
          >
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LeftSidebar;
