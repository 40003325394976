import Logo from "./logo.png";
import LogoTwo from "./logo2.png";
import LogoIcon from "./logo-icon.png";
import LogoFinal from "./logo-final.png";
import IntroLogoOne from "./intro-logo-1.mp4";
import IntroLogoTwo from "./intro-logo-2.mp4";
import IntroLogoThree from "./intro-logo-3.mp4";

export {
  Logo,
  LogoTwo,
  LogoIcon,
  LogoFinal,
  IntroLogoOne,
  IntroLogoTwo,
  IntroLogoThree,
};
