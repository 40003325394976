import React from "react";
import { useParams } from "react-router-dom";
import PunchAttendancePage from "./punchAttendancePage";
import AttendanceRegularizationPage from "./attendanceRegularizationPage";

const AttendanceDashboard = () => {
  const { type } = useParams();

  return (
    <div>
      {type === "punchAttendance" && <PunchAttendancePage />}
      {type === "attendanceRegularization" && <AttendanceRegularizationPage />}
    </div>
  );
};

export default AttendanceDashboard;
