import React, { createContext, useContext, useState, useEffect } from "react";

// Create a new UserContext
const UserContext = createContext();

// Custom hook to access the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// Provider component to wrap around parts of the app
// that need access to the user state
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const [loading, setLoading] = useState(true);

  // Signin function to store user in state and localStorage
  const handleSignin = (userData, accessToken, refreshToken) => {
    setUser(userData);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    setLoading(false);
  };

  // Signout function to remove user from state and localStorage
  const handleSignout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    setLoading(true);
  };

  // Check if the user is already signed in (persisted in localStorage)
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedAccessToken = localStorage.getItem("accessToken");
    const storedRefreshToken = localStorage.getItem("refreshToken");

    // Ensure the storedUser is valid before parsing
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }

    // Set tokens if they exist
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }
    if (storedRefreshToken) {
      setRefreshToken(storedRefreshToken);
    }

    setLoading(false);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        accessToken,
        refreshToken,
        loading,
        handleSignin,
        handleSignout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
