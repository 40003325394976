import React from "react";
import { styles } from "../../styles";
import { PunchAttendance } from "../../components/attendance";
import HeadingSection from "../../common/HeadingSection/HeadingSection";

const PunchAttendancePage = () => {
  return (
    <div className={`flex flex-col p-5`}>
      <HeadingSection title="Punch Attendance" />

      <div className="flex flex-1 justify-between items-center bg-[#212121] p-[1rem] mb-[1rem] rounded-xl gap-5">
        <h5
          className={`font-subtitle text-[1.5rem] py-[0.17rem] text-[#EDF6FF]`}
        >
          Punch Your Attendance
        </h5>
      </div>

      {/* PUNCH ATTENDANCE */}
      <PunchAttendance />
    </div>
  );
};

export default PunchAttendancePage;
