import React from "react";
import { Formik, Form, Field } from "formik";
import { InputAdornment, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const SearchBar = () => {
  return (
    <div>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={(values) => {
          console.log("Search term:", values.search);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <TextField
              fullWidth
              name="search"
              variant="outlined"
              placeholder="Search"
              value={values.search}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "0.3rem",
                  height: "2.5rem",
                  "& fieldset": {
                    borderColor: "rgba(215, 174, 131, 0.5) !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#D7AE83 !important",
                  },
                },
                "& input": {
                  height: "0.5rem",
                  overflowX: "auto",
                },
                "& .MuiOutlinedInput-input": {
                  color: "#ffffff",
                },
                "& .MuiInputLabel-root": {
                  color: "#999999",
                  fontWeight: "300 !important",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#ffffff",
                },
                display: "flex",
                width: "full",
                padding: "1rem",
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon sx={{ color: "#ffffff" }} />
                    </InputAdornment>
                  ),
                },
                inputLabel: {
                  style: { color: "#ffffff", fontWeight: "300 !important" },
                },
              }}
            />
            {/* <button type="submit">Search</button> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchBar;
