import { Box, Breadcrumbs, Link, Typography } from "@mui/joy";
import React from "react";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useLocation, useNavigate } from "react-router-dom";
import { styles } from "../../styles";
import { IconButton, useMediaQuery } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const HeadingSection = ({ title, onToggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Split the pathname into segments
  const paths = location.pathname.split("/").filter((segment) => segment);

  const isExtraSmall = useMediaQuery("(max-width:600px)");

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <Box
      sx={{
        position: "sticky",
      }}
    >
      <Breadcrumbs
        // size="sm"
        aria-label="breadcrumbs"
        separator={
          <ChevronRightRoundedIcon fontSize="sm" sx={{ color: "#777777" }} />
        }
        sx={{ pl: 0, pr: 0, pt: 0 }}
      >
        <Link
          underline="none"
          color="neutral"
          // href="/"
          onClick={() => handleNavigate("/attendances")}
          aria-label="Home"
        >
          <HomeRoundedIcon sx={{ color: "#D7AE83" }} />
        </Link>
        {paths.length > 0 ? (
          paths.map((path, index) => {
            const routeTo = `/${paths.slice(0, index + 1).join("/")}`;
            // For the last item, render as Typography instead of Link
            if (index === paths.length - 1) {
              return (
                <Typography
                  key={index}
                  color="primary"
                  sx={{ fontWeight: 500, fontSize: 12, color: "#D7AE83" }}
                >
                  {title || path.charAt(0).toUpperCase() + path.slice(1)}{" "}
                  {/* Default to segment if title is not provided */}
                </Typography>
              );
            }
            return (
              <Link
                key={index}
                underline="hover"
                color="neutral"
                // href={routeTo}
                sx={{ fontSize: 12, fontWeight: 500, color: "#adadad" }}
                onClick={() => handleNavigate(routeTo)}
              >
                {path.charAt(0).toUpperCase() + path.slice(1)}{" "}
                {/* Capitalize first letter */}
              </Link>
            );
          })
        ) : (
          <Typography
            color="primary"
            sx={{ fontWeight: 500, fontSize: 12, color: "#D7AE83" }}
          >
            {title}
          </Typography>
        )}
      </Breadcrumbs>
      <div className={`flex font-title mb-[2rem] text-${styles.titleSize}`}>
        <h2>{title}</h2>
      </div>
    </Box>
  );
};

export default HeadingSection;
