import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import PunchClockIcon from "@mui/icons-material/PunchClock";

export const attendancePageData = [
  {
    id: 1,
    attendanceId: 1,
    attendanceTitle: "Mark Attendance",
    attendanceDetails: [
      { subtitle: "Punch Attendance", routeName: "punchAttendance" },
    ],
    icon: PhotoCameraFrontOutlinedIcon,
  },
  {
    id: 2,
    attendanceId: 2,
    attendanceTitle: "Attendance Regularization",
    attendanceDetails: [
      {
        subtitle: "Attendance Regularization",
        routeName: "attendanceRegularization",
      },
    ],
    icon: PunchClockIcon,
  },
];
