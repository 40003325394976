import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
import AttendanceDetailsDialog from "./detailsDialog";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      /> */}
      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          backgroundColor: "#111111",
          fontSize: "1rem",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: {
              variant: "outlined",

              sx: {
                color: "#D7AE83",
                fontSize: "12px",
                fontWeight: "500",
                border: "1px solid #111111",
                borderRadius: "5px",
                padding: "6px 12px",
                "&:hover": {
                  color: "#EDF6FF",
                  border: "1px solid #111111",
                },
              },
            },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
}

const AttendanceRegularization = () => {
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dummyData = [
    {
      id: 1,
      empId: 12345690,
      empFirstName: "Sumangal",
      empLastName: "Dey",
      empWorkEmail: "sumangal.dey@vaarat.com",
      date: "2024-11-09",
      totalHoursWorked: "08 Hrs",
      punchDetails: [
        {
          sessionId: 1,
          punchType: "in",
          punchTime: "10:00:00",
          address: "Home , Barddhaman",
          image: "https://imageurl.in",
          reason: "work-from-home",
          comment: "",
          punchGeoCodeLat: 22.574354,
          punchGeoCodeLon: 88.362873,
          distanceFromOffice: "15966.25",
        },
        {
          sessionId: 1,
          punchType: "out",
          punchTime: "12:00:00",
          address: "Home , Barddhaman",
          image: "https://imageurl.in",
          reason: "work-from-home",
          comment: "",
          punchGeoCodeLat: 22.574354,
          punchGeoCodeLon: 88.362873,
          distanceFromOffice: "15966.25",
        },
        {
          sessionId: 2,
          punchType: "in",
          punchTime: "13:00:00",
          address: "Home , Barddhaman",
          image: "https://imageurl.in",
          reason: "work-from-home",
          comment: "",
          punchGeoCodeLat: 22.574354,
          punchGeoCodeLon: 88.362873,
          distanceFromOffice: "15966.25",
        },
        {
          sessionId: 2,
          punchType: "out",
          punchTime: "14:00:00",
          address: "Home , Barddhaman",
          image: "https://imageurl.in",
          reason: "work-from-home",
          comment: "",
          punchGeoCodeLat: 22.574354,
          punchGeoCodeLon: 88.362873,
          distanceFromOffice: "15966.25",
        },
        {
          sessionId: 3,
          punchType: "in",
          punchTime: "17:00:00",
          address: "Home , Barddhaman",
          image: "https://imageurl.in",
          reason: "work-from-home",
          comment: "",
          punchGeoCodeLat: 22.574354,
          punchGeoCodeLon: 88.362873,
          distanceFromOffice: "15966.25",
        },
      ],
    },
  ];

  // Function to handle row selection manually via checkbox only
  const handleCheckboxClick = (params, event) => {
    if (event.target.closest(".MuiCheckbox-root")) {
      return;
    }
    event.stopPropagation();
  };

  // Fetch attendance data from backend API
  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get("/api/attendance");
        setAttendanceData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setLoading(false);
      }
    };
    fetchAttendanceData();
  }, []);

  const handleClickOpenRowData = (data) => {
    setSelectedRowData(data);
    setOpenDetailsDialog(true);
  };

  const handleClickCloseRowData = () => {
    setOpenDetailsDialog(false);
    setSelectedRowData(null);
  };

  const handleClickOpen = (imgSrc) => {
    setCurrentImage(imgSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      cellClassName: "id-cell",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "empId",
      headerName: "Employee ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "empFirstName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-cell",
    },
    {
      field: "date",
      headerName: "Attendance Date",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalHoursWorked",
      headerName: "Worked Hours",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <IconButton
            color="primary"
            // onClick={() => handleViewClick(params.row.id)}
            onClick={() => handleClickOpenRowData(params.row)}
            sx={{
              backgroundColor: "#d7ae83",
              color: "#EDF6FF",
              "&:hover": {
                backgroundColor: "#111111",
                color: "#d7ae83",
              },
              borderRadius: "5px",
              height: "30px",
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <VisibilityOutlinedIcon sx={{ fontSize: 22 }} />
            <span style={{ fontSize: "14px", fontWeight: "500" }}>View</span>
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Box>
      <Box
        m="10px 0 0 0"
        height="100%"
        minHeight="100vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "12px",
            overflow: "hidden",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-cell": {
            color: "#d7ae83",
          },
          "& .id-cell": {
            color: "#d7ae83",
          },
          "& .role-cell": {
            color: "#d7ae83",
          },
          "& .status-cell": {
            color: "#d7ae83",
          },
          // Header Background Color
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#333333 !important",
          },

          // Header Title Color
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#111111 !important",
          },

          // Header Title Separator Color
          "& .MuiDataGrid-columnSeparator": {
            // visibility: "hidden",
            color: "#999999 !important",
          },

          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#252525",
            color: "#EDF6FF",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#EDF6FF",
          },
          "& .MuiDataGrid-toolbarContainer": {
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#e6ccb1",
            padding: "8px",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "#111111",
          },

          // Checkbox Color
          "& .MuiCheckbox-root": {
            color: "#e6ccb1",
          },
          // After Checked Color
          "& .MuiCheckbox-root.Mui-checked": {
            color: "#e6ccb1",
          },
          "& .MuiCheckbox-root.MuiCheckbox-indeterminate": {
            color: "#e6ccb1",
            // backgroundColor: "#000000",
          },

          // Remove focus outline
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          // Remove row selection highlight
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#505c66 !important",
          },
        }}
      >
        {loading ? (
          <CircularProgress
            sx={{
              color: "#d7ae83",
              position: "absolute",
              top: "30%",
              left: "50%",
            }}
          />
        ) : (
          <DataGrid
            // rows={attendanceData}
            rows={dummyData}
            columns={columns}
            checkboxSelection
            pageSize={5}
            rowsPerPageOptions={[5]}
            onCellClick={handleCheckboxClick}
            // {...assetsData}
            slots={{
              toolbar: CustomToolbar,
            }}
            sx={{
              width: "100%",
              minWidth: "850px",
              overflowX: "auto",
            }}
          />
        )}
      </Box>
      <Dialog
        open={open}
        // onClose={handleClose}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#333333",
            borderRadius: "20px",
            boxShadow: "none",
            position: "relative",
          },
        }}
      >
        {/* Cross Icon to Close the Image */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#EDF6FF",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <DialogContent
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={currentImage}
            alt="Expanded"
            style={{
              width: "500px",
              height: "500px",
              objectFit: "contain",
              borderRadius: "20px",
            }}
            // onClick={handleClose}
          />
        </DialogContent>
      </Dialog>

      {/* Attendance Details Dialog */}
      <AttendanceDetailsDialog
        open={openDetailsDialog}
        onClose={handleClickCloseRowData}
        attendanceDetails={selectedRowData}
      />
    </Box>
  );
};

export default AttendanceRegularization;
