// Function to mask the email
export const maskEmail = (input) => {
  const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValidCode = /^\d{8}$/;

  if (isValidEmail.test(input)) {
    const [localPart, domain] = input.split("@");
    if (!localPart || localPart.length <= 4) return input;

    const firstThree = localPart.slice(0, 3);
    // const lastThree = localPart.slice(-3);
    const maskedPart = localPart.slice(3).replace(/./g, "x");

    // return `${firstThree}${maskedPart}${lastThree}@${domain}`;
    return `${firstThree}${maskedPart}@${domain}`;
  } else if (isValidCode.test(input)) {
    // Retrieve empWorkEmail from local storage
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.empWorkEmail && isValidEmail.test(user.empWorkEmail)) {
      const [localPart, domain] = user.empWorkEmail.split("@");
      const firstThree = localPart.slice(0, 3);
      const maskedPart = localPart.slice(3).replace(/./g, "x");
      return `${firstThree}${maskedPart}@${domain}`;
    } else {
      return `${user?.empFirstName}`;
    }
  }

  return input;
};
