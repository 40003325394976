// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left_side_navbar_color {
  color: #EDF6FF;
  background-color: #282828;
}

.top_navbar_color {
  color: #EDF6FF;
  /* background-color: #090909; */
}

.logo_dropdown_color {
  background: linear-gradient(265deg, #050404 20%, #3e3d3d 110%);
}`, "",{"version":3,"sources":["webpack://./src/sections/sections.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,+BAA+B;AACjC;;AAEA;EACE,8DAA8D;AAChE","sourcesContent":[".left_side_navbar_color {\n  color: #EDF6FF;\n  background-color: #282828;\n}\n\n.top_navbar_color {\n  color: #EDF6FF;\n  /* background-color: #090909; */\n}\n\n.logo_dropdown_color {\n  background: linear-gradient(265deg, #050404 20%, #3e3d3d 110%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
