import React from "react";
import { styles } from "../../styles";
import AttendanceList from "./attendanceList";
import HeadingSection from "../../common/HeadingSection/HeadingSection";

const AttendancePage = () => {
  return (
    <div className={`flex flex-col p-5`}>
      <HeadingSection title="Attendance" />

      <div className="flex flex-1 justify-between items-center bg-[#212121] p-[1rem] mb-[1rem] rounded-xl gap-5">
        <h5
          className={`font-subtitle text-[1.5rem] py-[0.17rem] text-[#EDF6FF]`}
        >
          Attendance List
        </h5>
      </div>

      {/* ATTENDANCE LIST */}
      <AttendanceList />
    </div>
  );
};

export default AttendancePage;
