import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastNotification = () => {
  return (
    <div>
      <style>
        {`
          @media (max-width: 480px) {
            .Toastify__toast-container--top-center {
              left: 50% !important;
              transform: translateX(-50%) !important;
              width: 60% !important;
              margin-top: 20px !important;
            }
          }
        `}
      </style>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
        theme="colored"
        style={{
          width: "300px",
          margin: "0 auto",
          color: "#111111 !important",
        }}
      />
    </div>
  );
};

const showSuccessToast = (message) => {
  toast.success(message, {
    style: {
      backgroundColor: "#363636",
      color: "#D7AE83",
    },
  });
};

const showErrorToast = (message) => {
  toast.error(message, {
    style: {
      backgroundColor: "#363636",
      color: "#D7AE83",
    },
  });
};

export { ToastNotification, showSuccessToast, showErrorToast };
