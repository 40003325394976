import { Route, Routes, Navigate } from "react-router-dom";
import { AttendanceDashboard, AttendancePage, Dashboard } from "../pages";
import { useUser } from "../context/userContext";
import Signin from "../components/signin/signin";
import ProtectedRoute from "./protectedRoute";
import {
  LeftSidebar,
  SidebarVTwo,
  TopNavbar,
  TopNavbarVTwo,
} from "../sections";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

const AllRoutes = () => {
  const { user } = useUser();

  const isExtraSmall = useMediaQuery("(max-width:600px)");

  return (
    <Routes>
      <>
        {/* <Route path="/" element={<Signin />} /> */}
        <Route
          path="/"
          element={user ? <Navigate to="/attendances" /> : <Signin />}
        />
        {/* Secure /roles and /roles/addRole for Admin only */}
        {/* <Route
          path="/roles"
          element={
            <ProtectedRoute role="Admin">
              <RoleManage />
            </ProtectedRoute>
          }
        /> */}

        {/* Attendance Section */}
        <>
          <Route
            path="/attendances"
            element={
              <ProtectedRoute>
                <div className="app">
                  <LeftSidebar />
                  <main className="content">
                    <AttendancePage />
                  </main>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="/attendances/:type"
            element={
              <ProtectedRoute>
                <div className="app">
                  <LeftSidebar />
                  <main className="content">
                    <AttendanceDashboard />
                  </main>
                </div>
              </ProtectedRoute>
            }
          />
        </>
      </>
    </Routes>
  );
};

export default AllRoutes;
