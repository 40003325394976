import axios from "axios";

const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY;

// Function to get the address from the Geo-Code => lat, lng
export const geoCodingApiAddress = async (lat, lng) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
    );

    if (response.data) {
      // console.log(response);

      const address = response.data.results[0].formatted_address;
      return address ? address : "Address not found";
    } else {
      console.error("Geocoding API error:", response.data);
      return "Error to get location from API";
    }
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Request timeout");
    } else {
      console.error("Error fetching geolocation data", error);
    }
    return null;
  }
};
