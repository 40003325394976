import {
  Avatar,
  Box,
  CssBaseline,
  CssVarsProvider,
  Divider,
  GlobalStyles,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  ListItemContent,
  Sheet,
  Typography,
} from "@mui/joy";

import React, { useEffect, useState } from "react";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ApprovalIcon from "@mui/icons-material/Approval";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

// import { closeSidebar } from '../utils';
import { closeSidebar } from "../utils/sidebarToggler";

import { Logo } from "../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";

let menuData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <DashboardRoundedIcon />,
  },
  {
    title: "Employees",
    path: "/employees",
    icon: <AccountBoxIcon />,
  },
  {
    title: "Approvals",
    path: "/approvals",
    icon: <ApprovalIcon />,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <AssessmentIcon />,
  },
  {
    title: "Assets",
    path: "/assets",
    icon: <DevicesOtherIcon />,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <SettingsRoundedIcon />,
  },
  {
    title: "Role Management",
    path: "/roles",
    icon: <ManageAccountsIcon />,
  },
];

const SidebarVTwo = () => {
  const { user, handleSignout } = useUser();

  const [index, setIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const foundIndex = menuData.findIndex((el) => el.path == currentPath);
    setIndex(foundIndex);
  }, [location.pathname]);

  const handleNavigate = (path, i) => {
    setIndex(i);
    navigate(path);
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />

      <Sheet
        className="Sidebar"
        sx={{
          position: { xs: "fixed", md: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            md: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": "220px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "240px",
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: "var(--SideNavigation-slideIn)",
            backgroundColor: "var(--joy-palette-background-backdrop)",
            transition: "opacity 0.4s",
            transform: {
              xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)",
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <IconButton size="sm ">
            <img
              src={Logo}
              alt="logo"
              // onClick={handleLogoClick}
              className="w-[2.5rem] h-[3.5rem] object-contain cursor-pointer"
            />
          </IconButton>
          <Typography level="title-lg">HRMS User</Typography>
          {/* <ColorSchemeToggle sx={{ ml: 'auto' }} /> */}
        </Box>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="Search"
        />
        <Box
          sx={{
            minHeight: 200,
            height: 500,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
            // border: '1px solid',
            justifyContent: "space-between",
            borderRadius: "md",
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              // '--ListItem-radius': (theme) => theme.vars.radius.lg,
            }}
          >
            {menuData.map((el, i) => (
              <ListItem key={i}>
                <ListItemButton
                  role="menuitem"
                  component="a"
                  // href={el.path}
                  selected={i == index}
                  onClick={() => handleNavigate(el.path, i)}
                >
                  {el.icon}
                  <ListItemContent>
                    <Typography level="title-sm">{el.title}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar
            variant="outlined"
            size="sm"
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
          />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">{user.empName} </Typography>
            <Typography level="body-xs">{user.empRole}</Typography>
            <Typography level="body-xs">{user.empEmail}</Typography>
          </Box>
          <IconButton size="sm" variant="plain" color="neutral">
            <LogoutRoundedIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="body-xs">
            App Vresion : VRT.01 <br /> Last Published - 16 oct 24
          </Typography>
        </Box>
      </Sheet>
    </CssVarsProvider>
  );
};

export default SidebarVTwo;
