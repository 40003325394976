import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import React from "react";

const CustomDialogbox = ({
  open,
  handleClose,
  handleConfirm,
  title,
  message,
  disabled,
  buttonText,
  children,
  disableClose,
}) => {
  const handleDialogClose = (event) => {
    if (disableClose) {
      // If disabling close, do not call handleClose
      event.preventDefault();
    } else {
      handleClose();
    }
  };

  const isMobile = useMediaQuery("(max-width:512px)");

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          background: "linear-gradient(135deg, #000000 10%, #666666 150%)",
          borderRadius: "1rem",
          padding: isMobile ? "1rem" : "1.5rem",
          display: "flex",
          boxShadow: "none",
          position: "relative",
        },
      }}
      // disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        sx={{
          color: "#FFFFFF",
          padding: "0px",
          marginBottom: "1.5rem",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: "0px", marginBottom: "1.5rem" }}>
        <DialogContentText sx={{ color: "#FFFFFF !important" }}>
          {message}
        </DialogContentText>
        <Box mt={2}>{children}</Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0px" }}>
        <Box
          display="flex"
          justifyContent="flex-end"
          // p={1.5}
          gap={1.5}
          alignItems="center"
        >
          <Button
            onClick={handleClose}
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#111111",
              borderRadius: "0.3rem",
              padding: "5px 10px",
              "&:hover": {
                color: "#f70202",
                backgroundColor: "#111111",
              },
            }}
          >
            Cancel
          </Button>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "#999999",
              height: 25,
              alignSelf: "center",
            }}
          />

          <Button
            onClick={handleConfirm}
            disabled={disabled}
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#111111",
              borderRadius: "0.3rem",
              padding: "5px 10px",
              "&:hover": {
                color: "#d7ae83",
                backgroundColor: "#111111",
              },
              // Style for disabled state
              "&.Mui-disabled": {
                color: "#999999",
                backgroundColor: "#555555",
              },
            }}
            autoFocus
          >
            {buttonText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogbox;
